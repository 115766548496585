import React from 'react'

export default function Header() {
  return (
    <div class="container">
        <div class="row align-items-center my-5">
            <div class="col text-white text-center">
                <h1 class="font-weight-light">Jan Haller</h1>
            </div>
        </div>
  </div>
  )
}
