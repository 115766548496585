import React from 'react'

export default function Content() {
  return (
    <div class="container">
        <div class="row align-items-center">
            <div class="col text-white text-center">
                <h5 class="font-weight-light">more coming soon</h5>
            </div>
        </div>
  </div>
  )
}
