import Header from './Header'
import Contact from './Contact'
import Content from './Content'

function Home() {
  return (
    <>
      <Header />
      <Content />
      <Contact />
    </>
  )
}

export default Home;
